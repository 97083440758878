import { z } from 'zod'
import { BaseEntitySchema } from '~/schemas/base/base-entity'
import { requiredCountryCode } from '~/schemas/util'

export const CreateAddressSchema = z.object({
    country: requiredCountryCode(),
    line1_bg: z.string().default(''),
    line1_en: z.string().default(''),
    line2_bg: z.string().default(''),
    line2_en: z.string().default(''),
    city_bg: z.string().default(''),
    city_en: z.string().default(''),
    municipality_bg: z.string().default(''),
    municipality_en: z.string().default(''),
    state_bg: z.string().default(''),
    state_en: z.string().default(''),
    postalCode: z.string().default(''),
    phone: z.string().default(''),
    attention_bg: z.string().default(''),
    attention_en: z.string().default(''),
})

export const UpdateAddressSchema = CreateAddressSchema.partial()

export const AddressSchema = BaseEntitySchema.extend(
    CreateAddressSchema.required().shape,
)

export type CreateAddressRequest = z.infer<typeof CreateAddressSchema>
export type UpdateAddressRequest = z.infer<typeof UpdateAddressSchema>
export type AddressResponse = z.infer<typeof AddressSchema>
