import { z } from 'zod'
import { SubscriptionPlan } from '@prisma/client'
import { BaseEntitySchema } from '~/schemas/base/base-entity'
import {
    requiredCurrencyCode,
    requiredString,
    requiredUic,
} from '~/schemas/util'
import { CreateAddressSchema, AddressSchema } from '~/schemas/address'
import { FileSchema } from '~/schemas/drive'
import { TaxSchema } from '~/schemas/tax'
import { UnitSchema } from '~/schemas/unit'
import { SupportedCurrencySchema } from '~/schemas/currency'
import type { TranslationKey } from '~/types/i18n'
import { RoleSchema } from '../role'

export const CreateCompanySchema = z
    .object({
        id: z.string().uuid().optional(),
        name_bg: requiredString(),
        name_en: z.string().default(''),
        uic: requiredUic(),
        isVatRegistered: z.boolean(),
        vat: z.string().default(''),
        baseCurrencyCode: requiredCurrencyCode(),
        address: CreateAddressSchema,
    })
    .refine(
        ({ isVatRegistered, vat }) => {
            if (isVatRegistered) {
                return requiredString().safeParse(vat).success
            }
            return true
        },
        {
            path: ['vat'],
            params: {
                translationKey: 'invalid-vat-number' satisfies TranslationKey,
            },
        },
    )

export const UpdateCompanySchema = z
    .object({
        name_bg: requiredString(),
        name_en: z.string().default(''),
        uic: requiredUic(),
        isVatRegistered: z.boolean(),
        vat: z.string().default(''),
        address: CreateAddressSchema,
    })
    .partial()
    .refine(
        ({ isVatRegistered, vat }) => {
            if (isVatRegistered) {
                return requiredString().safeParse(vat).success
            }
            return true
        },
        {
            path: ['vat'],
            params: {
                translationKey: 'invalid-vat-number' satisfies TranslationKey,
            },
        },
    )

export const CompanySchema = BaseEntitySchema.extend({
    name_bg: requiredString(),
    name_en: z.string(),
    slug: requiredString(),
    uic: requiredUic(),
    isVatRegistered: z.boolean(),
    vat: z.string(),
    role: RoleSchema,
    selected: z.boolean(),
    logo: FileSchema.nullable(),
    baseCurrency: SupportedCurrencySchema,
    baseCurrencyCode: requiredCurrencyCode(),
    roles: z.array(RoleSchema),
    taxes: z.array(TaxSchema),
    units: z.array(UnitSchema),
    address: AddressSchema,
    stripeCustomerId: z.string().nullable(),
    stripeSubscriptionId: z.string().nullable(),
    stripePriceId: z.string().nullable(),
    subscriptionPlan: z.nativeEnum(SubscriptionPlan),
})

export const BasicCompanySchema = CompanySchema.pick({
    id: true,
    name_bg: true,
    name_en: true,
    slug: true,
    logo: true,
})

export type CreateCompanyRequest = z.infer<typeof CreateCompanySchema>
export type UpdateCompanyRequest = z.infer<typeof UpdateCompanySchema>

export type BasicCompanyResponse = z.infer<typeof BasicCompanySchema>
export type CompanyResponse = z.infer<typeof CompanySchema>
