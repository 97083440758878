import { z } from 'zod'
import { ContactType } from '@prisma/client'
import { OrderDirection } from '~/schemas/common'
import {
    createFiltersSchema,
    createOrderSchema,
    createPaginatedResponseSchema,
    requiredCountryCode,
} from '~/schemas/util'
import { ContactSchema } from './contact'

export const ContactOrderSchema = createOrderSchema(
    z.enum(['name', 'createdAt']),
    'createdAt',
    OrderDirection.asc,
)

export const ContactFiltersSchema = createFiltersSchema(
    z.object({
        contactType: z.nativeEnum(ContactType).optional(),
        search: z.string().optional(),
        country: requiredCountryCode().optional(),
    }),
)
export const PaginatedContactsSchema = createPaginatedResponseSchema(
    ContactSchema,
    ContactOrderSchema,
    ContactFiltersSchema,
)

export type ContactOrderQuery = z.infer<typeof ContactOrderSchema>
export type ContactFiltersQuery = z.infer<typeof ContactFiltersSchema>
export type PaginatedContactsResponse = z.infer<typeof PaginatedContactsSchema>
