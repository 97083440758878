import { z } from 'zod'
import { Locale, CompanyUserStatus } from '@prisma/client'
import { BaseEntitySchema } from '~/schemas/base/base-entity'
import { requiredString, requiredDateTime, requiredUuid } from '~/schemas/util'
import { BasicCompanySchema, CompanySchema } from '~/schemas/company'
import { RoleSchema } from './role'

export const InviteUserSchema = z.object({
    firstName: requiredString(),
    lastName: requiredString(),
    email: z.string().email(),
    roleId: requiredUuid(),
})

export const UpdateUserSchema = z.object({
    email: z.string().email().optional(),
    firstName: z.string().min(1, 'Please enter a valid first name').optional(),
    lastName: z.string().min(1, 'Please enter a valid last name').optional(),
})

export const InvitationSchema = z.object({
    company: BasicCompanySchema,
    token: requiredString(),
    createdAt: requiredDateTime(),
    updatedAt: requiredDateTime(),
    email: z.string().email(),
    firstName: requiredString(),
    lastName: requiredString(),
    role: RoleSchema,
    roleId: requiredUuid(),
})

export const UserSchema = BaseEntitySchema.extend({
    email: z.string().email(),
    firstName: requiredString(),
    lastName: requiredString(),
    isEmailConfirmed: z.boolean(),
    companies: z.array(CompanySchema),
    invitations: z.array(InvitationSchema),
    locale: z.nativeEnum(Locale),
})

export const CompanyUserSchema = BaseEntitySchema.extend({
    email: z.string().email(),
    firstName: requiredString(),
    lastName: requiredString(),
    isEmailConfirmed: z.boolean(),
    status: z.nativeEnum(CompanyUserStatus),
    role: RoleSchema,
    roleId: requiredUuid(),
})

export const UpdateCompanyUserSchema = z.object({
    roleId: requiredUuid(),
})

export const ChangePasswordSchema = z.object({
    oldPassword: requiredString(),
    newPassword: requiredString(),
    newPasswordConfirm: requiredString(),
})

export type InviteUserRequest = z.infer<typeof InviteUserSchema>
export type UpdateUserRequest = z.infer<typeof UpdateUserSchema>
export type UserResponse = z.infer<typeof UserSchema>
export type CompanyUserResponse = z.infer<typeof CompanyUserSchema>
export type UpdateCompanyUserRequest = z.infer<typeof UpdateCompanyUserSchema>

export type ChangePasswordRequest = z.infer<typeof ChangePasswordSchema>
export type InvitationResponse = z.infer<typeof InvitationSchema>
