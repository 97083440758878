export enum ErrorCode {
    unknown_error = 'unknown_error',

    // Auth
    invalid_access_token = 'invalid_access_token',
    invalid_refresh_token = 'invalid_refresh_token',
    invalid_confirmation_token = 'invalid_confirmation_token',
    user_not_found = 'user_not_found',
    user_already_exists = 'user_already_exists',
    wrong_password = 'wrong_password',
    passwords_dont_match = 'passwords_dont_match',
    new_password_same_as_old_password = 'new_password_same_as_old_password',
    token_expired = 'token_expired',
    token_forbidden = 'token_forbidden',
    email_already_confirmed = 'email_already_confirmed',
    already_member = 'already_member',

    // User Account
    cannot_delete_account_with_company_ownership = 'cannot_delete_account_with_company_ownership',

    // Common
    too_many_requests = 'too_many_requests',

    // Currency
    exchange_rate_unavailable = 'exchange_rate_unavailable',

    // Company
    company_manager_required = 'company_manager_required',
    company_uic_exists = 'company_uic_exists',

    // Contact
    contact_exists = 'contact_exists',

    // Invitations
    invalid_invitation_token = 'invalid_invitation_token',
    invitation_not_found = 'invitation_not_found',
    user_already_member = 'user_already_member',
    user_already_invited = 'user_already_invited',

    // Item
    item_used_in_documents = 'item_used_in_documents',

    // Documents common
    document_number_exists = 'document_number_exists',
    issue_date_after_due_date = 'issue_date_after_due_date',
    line_item_required = 'line_item_required',
    total_cannot_be_negative = 'total_cannot_be_negative',

    // Invoice
    vat_non_charge_reason_in_use = 'vat_non_charge_reason_in_use',

    // Credit Note
    credit_exceeds_invoice_total = 'credit_exceeds_invoice_total',

    // Company accounts
    account_number_exists = 'account_number_exists',

    // Bank accounts
    bank_account_exists = 'bank_account_exists',

    // Roles
    role_assigned_to_users = 'role_assigned_to_users',
}
