import type {
    BulkDeleteRequest,
    BulkMoveRequest,
    CreateFolderRequest,
    DriveInfoResponse,
    FileResponse,
    FolderOrderQuery,
    FolderResponse,
    OrderedFolderResponse,
    RenameFileRequest,
    RenameFolderRequest,
} from '~/schemas'

const baseUrl = (companyId: string) => `/companies/${companyId}/drive`

export const drive = {
    info(companyId: string) {
        return apiClient<DriveInfoResponse>(`${baseUrl(companyId)}`)
    },

    search(companyId: string, search: string) {
        return apiClient<FileResponse[]>(`${baseUrl(companyId)}/search`, {
            query: { search },
        })
    },

    createFolder(companyId: string, createFolderRequest: CreateFolderRequest) {
        return apiClient<FolderResponse>(`${baseUrl(companyId)}/folders`, {
            method: 'POST',
            body: createFolderRequest,
        })
    },

    getFolders(companyId: string) {
        return apiClient<FolderResponse[]>(`${baseUrl(companyId)}/folders`)
    },

    getFolder(
        companyId: string,
        folderId: string,
        params?: {
            order?: FolderOrderQuery
        },
    ) {
        return apiClient<OrderedFolderResponse>(
            `${baseUrl(companyId)}/folders/${folderId}`,
            {
                params: {
                    ...params?.order,
                },
            },
        )
    },

    renameFolder(
        companyId: string,
        folderId: string,
        renameFolderRequest: RenameFolderRequest,
    ) {
        return apiClient<FolderResponse>(
            `${baseUrl(companyId)}/folders/${folderId}`,
            {
                method: 'PATCH',
                body: renameFolderRequest,
            },
        )
    },

    uploadFiles(companyId: string, folderId: string, files: File[]) {
        const formData = new FormData()
        Array.from(files).forEach((file) => formData.append('file', file))

        return apiClient<FileResponse>(
            `${baseUrl(companyId)}/folders/${folderId}`,
            {
                method: 'POST',
                body: formData,
            },
        )
    },

    getFile(companyId: string, fileId: string) {
        return apiClient<FileResponse>(`${baseUrl(companyId)}/files/${fileId}`)
    },

    async downloadFile(companyId: string, fileId: string) {
        const file = await this.getFile(companyId, fileId)

        await downloadFileUrl(file.url, file.name)
    },

    renameFile(
        companyId: string,
        fileId: string,
        renameFileRequest: RenameFileRequest,
    ) {
        return apiClient<FileResponse>(
            `${baseUrl(companyId)}/files/${fileId}`,
            {
                method: 'PATCH',
                body: renameFileRequest,
            },
        )
    },

    bulkMove(companyId: string, bulkMoveRequest: BulkMoveRequest) {
        return apiClient<void>(`${baseUrl(companyId)}/bulk-move`, {
            method: 'POST',
            body: bulkMoveRequest,
        })
    },

    bulkDelete(companyId: string, bulkDeleteRequest: BulkDeleteRequest) {
        return apiClient<void>(`${baseUrl(companyId)}/bulk-delete`, {
            method: 'POST',
            body: bulkDeleteRequest,
        })
    },
}
