<template>
    <component
        :is="is"
        :to="to"
        :disabled="disabled || loading"
        :type="type"
        :class="[
            'relative inline-flex flex-shrink-0 cursor-pointer select-none items-center justify-center whitespace-nowrap rounded-md border border-transparent align-middle font-medium no-underline outline-none transition-shadow focus:outline-none focus:ring-2 focus:ring-offset-2',
            buttonClasses,
        ]"
        :tabindex="disabled ? -1 : 0"
    >
        <div
            :class="[
                'pointer-events-none relative flex w-full shrink-0 flex-nowrap items-center justify-center text-inherit transition-opacity',
                loading && 'opacity-30',
                icon && 'text-inherit',
            ]"
        >
            <slot />
        </div>

        <Transition
            appear
            enter-from-class="opacity-0"
            enter-active-class="transition-opacity"
            enter-to-class="opacity-100"
            leave-from-class="opacity-100"
            leave-active-class="transition-opacity"
            leave-to-class="opacity-0"
        >
            <span
                v-if="loading"
                class="absolute inset-0 flex h-full w-full items-center justify-center"
            >
                <BaseProgressCircular size="70%" class="loader" />
            </span>
        </Transition>
    </component>
</template>

<script setup lang="ts">
import { computed, type Component } from 'vue'

const props = defineProps(buttonProps)

const buttonClasses = computed(() => [
    buttonThemeVariationClasses[props.variant][props.color],
    sizeClasses[props.size],
    props.icon
        ? `${iconSizeClasses[props.size]} min-w-[unset]`
        : paddingClasses[props.size],
    borderClasses,
    {
        [disabledClasses]: props.disabled,
        [loadingClasses]: props.loading,
    },
])

const svgIconClassSize = computed(
    () =>
        ({
            xs: '1rem',
            sm: '1rem',
            default: '1.25rem',
            lg: '1.35rem',
            xl: '1.5rem',
        })[props.size],
)
</script>

<script lang="ts">
export type ButtonVariant = 'flat' | 'text' | 'elevated'
export type ButtonColor =
    | 'primary'
    | 'primary-light'
    | 'secondary'
    | 'success'
    | 'error'
    | 'warning'
    | 'info'
export type ButtonSize = 'xs' | 'sm' | 'default' | 'lg' | 'xl'

const flatVariant: Record<ButtonColor, string> = {
    primary:
        'bg-primary-600 hover:bg-primary-700 text-white focus:ring-primary-600',
    ['primary-light']:
        'bg-primary-200 hover:bg-primary-300 text-primary-700 focus:ring-primary-300',
    secondary:
        'bg-gray-200 hover:bg-gray-300 text-gray-900 focus:ring-gray-300',
    success:
        'bg-green-600 hover:bg-green-700 text-white focus:ring-green-600 focus:ring-green-600',
    error: 'bg-red-600 hover:bg-red-700 text-white focus:ring-red-600 focus:ring-red-600',
    warning:
        'bg-yellow-600 hover:bg-yellow-700 text-white focus:ring-yellow-600 focus:ring-warning-600',
    info: 'bg-info-600 hover:bg-info-700 text-white focus:ring-info-600 focus:ring-info-600',
}

const textVariant = {
    primary:
        'text-primary-600 hover:bg-primary-100 bg-transparent focus:ring-primary-600',
    ['primary-light']:
        'text-primary-300 hover:bg-primary-200 bg-transparent focus:ring-primary-300',
    secondary:
        'text-gray-600 hover:bg-gray-200 bg-transparent focus:ring-gray-300',
    success:
        'text-green-600 hover:bg-green-100 bg-transparent focus:ring-green-600',
    error: 'text-red-600 hover:bg-red-100 bg-transparent focus:ring-red-600',
    warning:
        'text-yellow-600 hover:bg-yellow-100 bg-transparent focus:ring-yellow-600',
    info: 'text-info-600 hover:bg-info-100 bg-transparent focus:ring-info-600',
}

export const buttonThemeVariationClasses: Record<
    ButtonVariant,
    Record<ButtonColor, string>
> = {
    flat: flatVariant,
    text: textVariant,
    elevated: (Object.keys(flatVariant) as ButtonColor[]).reduce(
        (acc, key) => {
            return {
                ...acc,
                [key]: `${flatVariant[key]} shadow-md`,
            }
        },
        {} as Record<ButtonColor, string>,
    ),
}

export const sizeClasses = {
    xs: 'h-6 !rounded text-xs min-w-[36px]',
    sm: 'h-7 text-sm min-w-[50px]',
    default: 'h-9 text-sm min-w-[64px]',
    lg: 'h-11 text-base min-w-[78px]',
    xl: 'h-14 text-base min-w-[92px]',
}

export const paddingClasses = {
    xs: 'px-2',
    sm: 'px-3',
    default: 'px-4',
    lg: 'px-5',
    xl: 'px-6',
}

export const iconSizeClasses = {
    xs: 'w-6 min-w-unset',
    sm: 'w-7',
    default: 'w-9',
    lg: 'w-11',
    xl: 'w-14',
}

export const borderClasses =
    'focus:ring-2 focus:ring-offset-2 focus:outline-none'

export const disabledClasses =
    'pointer-events-none bg-opacity-40 text-opacity-40 !cursor-not-allowed'
export const loadingClasses = 'pointer-events-none bg-opacity-30'

export const buttonProps = {
    variant: {
        type: String as PropType<ButtonVariant>,
        default: 'flat',
    },
    color: {
        type: String as PropType<ButtonColor>,
        default: 'secondary',
    },
    size: {
        type: String as PropType<ButtonSize>,
        default: 'default',
    },
    type: {
        type: String as PropType<HTMLButtonElement['type']>,
        default: 'button',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    icon: {
        type: Boolean,
        default: false,
    },
    is: {
        type: [String, Object] as PropType<string | Component>,
        default: 'button',
    },
    to: {
        type: String,
        default: '',
    },
}
</script>

<style scoped>
:slotted(svg) {
    width: v-bind(svgIconClassSize);
    height: v-bind(svgIconClassSize);
    flex-shrink: 0;
}
</style>
