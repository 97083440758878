import { z } from 'zod'
import { BaseEntitySchema } from '~/schemas/base/base-entity'
import { requiredString, requiredUuid } from '~/schemas/util'
import { UnitSchema } from '~/schemas/unit'
import { AccountSchema } from '~/schemas/account'

export const CreateItemSchema = z.object({
    name_bg: requiredString(),
    name_en: z.string().default(''),
    description: z.string().default(''),
    rate: z.number().positive(),
    unitId: requiredUuid(),
    accountId: requiredUuid(),
    active: z.boolean().default(true),
})

export const ItemSchema = BaseEntitySchema.extend(
    CreateItemSchema.extend({
        unit: UnitSchema,
        account: AccountSchema.nullable(),
    }).shape,
)

export type CreateItemRequest = z.infer<typeof CreateItemSchema>
export type UpdateItemRequest = z.infer<typeof CreateItemSchema>
export type ItemResponse = z.infer<typeof ItemSchema>
