import { z } from 'zod'
import { OrderDirection, type PaginationQuery } from '~/schemas/common'
import {
    createFiltersSchema,
    createOrderSchema,
    createPaginatedResponseSchema,
    requiredDateTime,
    requiredUuid,
} from '~/schemas/util'
import { ProformaInvoiceSchema } from './proforma-invoice'
import { ProformaInvoiceStatus } from './proforma-invoice-status'

export const ProformaInvoiceOrderSchema = createOrderSchema(
    z.enum([
        'displayNumber',
        'contact.name',
        'issueDate',
        'totalInBaseCurrency',
        'createdAt',
    ]),
    'createdAt',
    OrderDirection.desc,
)

export const ProformaInvoiceFiltersSchema = createFiltersSchema(
    z.object({
        contactId: requiredUuid().optional(),
        search: z.string().optional(),
        dateFrom: requiredDateTime().optional(),
        dateTo: requiredDateTime().optional(),
        status: z
            .union([
                z.nativeEnum(ProformaInvoiceStatus),
                z.array(z.nativeEnum(ProformaInvoiceStatus)),
            ])
            .optional()
            .transform((value) =>
                value && typeof value === 'string' ? [value] : value,
            ),
    }),
)
export const PaginatedProformaInvoicesSchema = createPaginatedResponseSchema(
    ProformaInvoiceSchema,
    ProformaInvoiceOrderSchema,
    ProformaInvoiceFiltersSchema,
)

export type ProformaInvoiceOrderQuery = z.infer<
    typeof ProformaInvoiceOrderSchema
>
export type ProformaInvoiceFiltersQuery = z.infer<
    typeof ProformaInvoiceFiltersSchema
>
export type PaginatedProformaInvoicesRequest = {
    pagination: PaginationQuery
    order: ProformaInvoiceOrderQuery
    filters: ProformaInvoiceFiltersQuery
}
export type PaginatedProformaInvoicesResponse = z.infer<
    typeof PaginatedProformaInvoicesSchema
>
