import { z } from 'zod'
import {
    ContactType,
    ExpenseDocumentStatus,
    ExpenseDocumentType,
    TaxMethod,
} from '@prisma/client'
import { BaseEntitySchema } from '~/schemas/base/base-entity'
import {
    checkPaymentMethodOther,
    requiredString,
    requiredDateTime,
    requiredCurrencyCode,
    requiredUuid,
    optionalDateTime,
    requiredCountryCode,
} from '~/schemas/util'
import {
    CreatePaymentMethodSchema,
    PaymentMethodSchema,
    ContactSchema,
    FileSchema,
    CreateAddressSchema,
    UpdateContactSettingsSchema,
} from '~/schemas'
import {
    CreateExpenseLineItemSchema,
    ExpenseLineItemSchema,
} from './expense-line-item'

export const CreateExpenseSchema = z
    .object({
        type: z.nativeEnum(ExpenseDocumentType),
        contactId: requiredUuid(),
        displayNumber: requiredString(),
        issueDate: requiredDateTime(),
        taxableEventDate: requiredDateTime(),
        dueDate: optionalDateTime(),
        currencyCode: requiredCurrencyCode(),
        exchangeRate: z.number().default(1),
        notes: z.string().default(''),
        isVatRegistered: z.boolean(),
        taxMethod: z.nativeEnum(TaxMethod),
        status: z.nativeEnum(ExpenseDocumentStatus),
        lineItems: CreateExpenseLineItemSchema.array().min(1),
    })
    .extend(CreatePaymentMethodSchema.shape)
    .superRefine(checkPaymentMethodOther)

export const ExpenseSchema = BaseEntitySchema.extend({
    type: z.nativeEnum(ExpenseDocumentType),
    displayNumber: z.string(),
    issueDate: requiredDateTime(),
    dueDate: optionalDateTime(),
    taxableEventDate: requiredDateTime(),
    currencyCode: requiredCurrencyCode(),
    notes: z.string().default(''),
    subtotal: z.number(),
    subtotalInBaseCurrency: z.number(),
    taxTotal: z.number(),
    taxTotalInBaseCurrency: z.number(),
    total: z.number(),
    totalInBaseCurrency: z.number(),
    exchangeRate: z.number().default(1),
    isVatRegistered: z.boolean(),
    taxMethod: z.nativeEnum(TaxMethod),
    attachments: z.array(FileSchema),
    status: z.nativeEnum(ExpenseDocumentStatus),
    contact: ContactSchema,
    contactId: requiredUuid(),
    lineItems: ExpenseLineItemSchema.array().min(1),
}).extend(PaymentMethodSchema.shape)

export const ExpenseFromAIResponseSchema = z.object({
    status: z.nativeEnum(ExpenseDocumentStatus),
    type: z.nativeEnum(ExpenseDocumentType),
    displayNumber: z.string().describe('Document number'),
    issueDate: z.string().nullish().describe('Issue date or null'),
    dueDate: z.string().nullish().describe('Due date or null'),
    taxableEventDate: z
        .string()
        .nullish()
        .describe('Taxable event date or null'),
    currencyCode: z.string().describe('ISO 4217 currency code'),
    notes: z
        .string()
        .describe('Notes or any additional information found in the document'),
    paymentMethod: PaymentMethodSchema.shape.paymentMethod,
    taxMethod: z
        .nativeEnum(TaxMethod)
        .describe(
            'if whole sum is taxed = PER_TOTAL; else if each line item is taxed = PER_LINE; else = NO_TAX',
        ),
    contact: z
        .object({
            type: z.literal(ContactType.SUPPLIER).array(),
            name: z
                .string()
                .describe('Supplier name (the issuer of the expense document)'),
            email: z.string(),
            uic: z.string().describe('Unique Identification Code'),
            vat: z
                .string()
                .describe('VAT number excluding country code prefix'),
            countryCode: z.string().describe('ISO 3166-1 alpha-2'),
            defaultCurrencyCode: z
                .string()
                .describe('ISO 4217 currency code, same as currencyCode'),
            address: z
                .object({
                    country: z.string().describe('ISO 3166-1 alpha-2'),
                    line1_bg: z.string(),
                    line1_en: z.string(),
                    line2_bg: z.string(),
                    line2_en: z.string(),
                    city_bg: z.string(),
                    city_en: z.string(),
                    municipality_bg: z.string(),
                    municipality_en: z.string(),
                    state_bg: z.string(),
                    state_en: z.string(),
                    postalCode: z.string(),
                    phone: z.string(),
                    attention_bg: z.string(),
                    attention_en: z.string(),
                })
                .describe(
                    'The address of the supplier/vendor who issued the expense',
                ),
        })
        .describe('Information of the supplier/vendor who issued the expense'),
    lineItems: z
        .object({
            accountId: z
                .string()
                .nullish()
                .describe(
                    'Corresponding account id from company context accounts array',
                ),
            description: z.string(),
            preTaxPrice: z.number(),
            taxId: z
                .string()
                .nullish()
                .describe(
                    'Corresponding tax id from company context taxes array',
                ),
        })
        .array(),
    total: z.number(),
})

export const OcrExpenseAttachmentSchema = z
    .object({
        type: z.nativeEnum(ExpenseDocumentType),
        contact: z.object({
            id: requiredUuid().optional(),
            type: z.nativeEnum(ContactType).array(),
            name: requiredString().catch(() => ''),
            uic: z.string().catch(() => ''),
            vat: z.string().catch(() => ''),
            email: z
                .string()
                .email()
                .catch(() => ''),
            countryCode: requiredCountryCode().catch(() => ''),
            defaultCurrencyCode: requiredCurrencyCode().catch(() => ''),
            address: CreateAddressSchema.partial(),
            settings: UpdateContactSettingsSchema,
        }),
        contactId: requiredUuid().optional(),
        displayNumber: z.string().optional(),
        issueDate: requiredDateTime()
            .optional()
            .catch(() => undefined),
        taxableEventDate: requiredDateTime()
            .optional()
            .catch(() => undefined),
        dueDate: optionalDateTime()
            .optional()
            .catch(() => undefined),
        currencyCode: requiredCurrencyCode()
            .optional()
            .catch(() => undefined),
        notes: z
            .string()
            .optional()
            .catch(() => undefined),
        taxMethod: z
            .nativeEnum(TaxMethod)
            .optional()
            .catch(() => undefined),
        status: z
            .nativeEnum(ExpenseDocumentStatus)
            .optional()
            .catch(() => undefined),
        lineItems: CreateExpenseLineItemSchema.extend({
            accountId: requiredUuid().nullable(),
        })
            .array()
            .min(1)
            .catch(() => []),
        total: z.number(),
    })
    .extend(CreatePaymentMethodSchema.shape)
    .extend({
        inconsistencyWarning: z.boolean().optional(),
    })

export type CreateExpenseRequest = z.infer<typeof CreateExpenseSchema>
export type UpdateExpenseRequest = z.infer<typeof CreateExpenseSchema>
export type ExpenseResponse = z.infer<typeof ExpenseSchema>
export type ExpenseFromAIResponse = z.infer<typeof ExpenseFromAIResponseSchema>
export type OcrExpenseAttachmentResponse = z.infer<
    typeof OcrExpenseAttachmentSchema
>
