import { z } from 'zod'
import { Permission } from '@prisma/client'
import { BaseEntitySchema } from './base/base-entity'
import { requiredString } from './util'

export const CreateRoleSchema = z.object({
    name_bg: requiredString(),
    name_en: z.string().default(''),
    description_bg: z.string().default(''),
    description_en: z.string().default(''),
    permissions: z.array(z.nativeEnum(Permission)),
})

export const RoleSchema = BaseEntitySchema.extend(CreateRoleSchema.shape)

export type CreateRoleRequest = z.infer<typeof CreateRoleSchema>
export type UpdateRoleRequest = z.infer<typeof CreateRoleSchema>
export type RoleResponse = z.infer<typeof RoleSchema>
