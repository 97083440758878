<template>
    <div class="fixed bottom-0 left-0 right-0 z-30 flex w-full justify-end">
        <div
            class="mx-4 mb-2 flex w-full max-w-[400px] flex-col gap-4 rounded-lg bg-gray-800 p-5 shadow-lg"
        >
            <p class="flex items-center gap-2 text-lg font-bold text-white">
                <CookieSVG class="h-6" />
                {{ t('privacy-policy.title') }}
            </p>

            <i18n-t
                keypath="privacy-policy.description"
                class="text-sm text-gray-300"
                tag="p"
            >
                <template #link>
                    <a
                        :href="privacyLink"
                        class="text-blue-400 hover:underline"
                        target="_blank"
                    >
                        {{ t('here') }}
                    </a>
                </template>
            </i18n-t>

            <div class="flex w-full gap-4">
                <!-- Accept -->
                <BaseButton
                    color="primary"
                    class="flex-1"
                    @click="allowPrivacyCookies(true)"
                >
                    {{ t('privacy-policy.accept') }}
                </BaseButton>

                <!-- Decline -->
                <BaseButton
                    variant="text"
                    class="group"
                    @click="allowPrivacyCookies(false)"
                >
                    <span class="text-gray-400 group-hover:text-black">
                        {{ t('privacy-policy.decline') }}
                    </span>
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import CookieSVG from '@/assets/svg/cookie.svg'

const { allowPrivacyCookies } = useAppStore()

const { t } = useI18n()
const { EFIRMA_WEBSITE_URL } = useRuntimeConfig().public

const privacyLink = `${EFIRMA_WEBSITE_URL}/privacy`
</script>
