import { z } from 'zod'
import { Locale, RevenueDocumentStatus, TaxMethod } from '@prisma/client'
import {
    requiredString,
    requiredDateTime,
    requiredCurrencyCode,
    requiredUuid,
    optionalDateTime,
} from '~/schemas/util'
import { AccountSchema } from '~/schemas/account'
import { TaxSchema } from '~/schemas/tax'
import { UnitSchema } from '~/schemas/unit'
import { CompanySchema } from '~/schemas/company'
import { ContactSchema } from '~/schemas/contact'
import { VatNonChargeReasonSchema } from '~/schemas/vat-non-charge-reasons'
import { BaseEntitySchema } from './base-entity'

/**
 * Line item
 */

export const BaseCreateRevenueLineItemSchema = z.object({
    name_bg: requiredString(),
    name_en: requiredString(),
    quantity: z.number(),
    rate: z.number().nonnegative(),
    description: z.string().default(''),
    taxId: requiredUuid().nullable(),
    itemId: requiredUuid().nullable(),
    unitId: requiredUuid(),
    accountId: requiredUuid(),
})
export const BaseRevenueLineItemSchema = BaseEntitySchema.extend({
    total: z.number(),
    quantity: z.number(),
    rate: z.number(),
    itemId: requiredUuid().nullable(),
    name_bg: requiredString(),
    name_en: requiredString(),
    description: z.string().default(''),
    taxId: requiredUuid().nullable(),
    tax: TaxSchema.nullable(),
    unitId: requiredUuid(),
    unit: UnitSchema,
    accountId: requiredUuid(),
    account: AccountSchema.nullable(),
    invoiceId: requiredUuid().optional(),
})

/**
 * Revenue
 */

export const BaseCreateRevenueSchema = z.object({
    contactId: requiredUuid(),
    numberPrefix: z.string().min(0).max(9),
    number: z.number().int().min(0).max(999_999_999),
    displayNumber: z.string().length(10),
    issueDate: requiredDateTime(),
    taxableEventDate: requiredDateTime(),
    dueDate: optionalDateTime(),
    currencyCode: requiredCurrencyCode(),
    exchangeRate: z.number().positive().default(1),
    notes: z.string().default(''),
    status: z.nativeEnum(RevenueDocumentStatus),
    locale: z.nativeEnum(Locale),
    isVatRegistered: z.boolean(),
    taxMethod: z.nativeEnum(TaxMethod),
    vatNonChargeReasonId: requiredUuid().nullable(),
    lineItems: z.array(BaseCreateRevenueLineItemSchema).min(1),
})
export const BaseRevenueSchema = BaseEntitySchema.extend({
    numberPrefix: z.string(),
    number: z.number(),
    displayNumber: z.string(),
    issueDate: requiredDateTime(),
    dueDate: optionalDateTime(),
    currencyCode: requiredCurrencyCode(),
    notes: z.string().default(''),
    taxableEventDate: requiredDateTime(),
    status: z.nativeEnum(RevenueDocumentStatus),
    locale: z.nativeEnum(Locale),
    isVatRegistered: z.boolean(),
    taxMethod: z.nativeEnum(TaxMethod),
    subtotal: z.number(),
    subtotalInBaseCurrency: z.number(),
    taxTotal: z.number(),
    taxTotalInBaseCurrency: z.number(),
    total: z.number(),
    totalInBaseCurrency: z.number(),
    exchangeRate: z.number().default(1),
    vatNonChargeReasonId: requiredUuid().nullable(),
    vatNonChargeReason: VatNonChargeReasonSchema.nullable(),
    companyId: requiredUuid(),
    company: CompanySchema,
    contactId: requiredUuid(),
    contact: ContactSchema,
    lineItems: z.array(BaseRevenueLineItemSchema).min(1),
})
