import type {
    RoleResponse,
    CreateRoleRequest,
    UpdateRoleRequest,
} from '~/schemas'

const baseUrl = (companyId: string) => `/companies/${companyId}/roles`

export const roles = {
    create(companyId: string, createRoleRequest: CreateRoleRequest) {
        return apiClient<RoleResponse>(baseUrl(companyId), {
            method: 'POST',
            body: createRoleRequest,
        })
    },

    findAll(companyId: string) {
        return apiClient<RoleResponse[]>(baseUrl(companyId))
    },

    findOne(companyId: string, roleId: string) {
        return apiClient<RoleResponse>(`${baseUrl(companyId)}/${roleId}`)
    },

    update(
        companyId: string,
        roleId: string,
        updateRoleRequest: UpdateRoleRequest,
    ) {
        return apiClient<RoleResponse>(`${baseUrl(companyId)}/${roleId}`, {
            method: 'PUT',
            body: updateRoleRequest,
        })
    },

    remove(companyId: string, roleId: string) {
        return apiClient<RoleResponse>(`${baseUrl(companyId)}/${roleId}`, {
            method: 'DELETE',
        })
    },
}
