import { z } from 'zod'
import { ContactType } from '@prisma/client'
import { BaseEntitySchema } from '~/schemas/base/base-entity'
import {
    requiredCountryCode,
    requiredString,
    requiredUic,
} from '~/schemas/util'
import { CreateAddressSchema, AddressSchema } from '~/schemas/address'
import { SupportedCurrencySchema } from '~/schemas/currency'
import {
    ContactSettingsSchema,
    UpdateContactSettingsSchema,
} from './contact-settings'
import type { TranslationKey } from '~/types/i18n'

export const CreateContactSchema = z
    .object({
        type: z.nativeEnum(ContactType).array(),
        name: requiredString(),
        uic: z.string().default(''),
        vat: z.string().default(''),
        email: z.string().email().or(z.literal('')),
        countryCode: requiredCountryCode(),
        defaultCurrencyCode: requiredString(),
        address: CreateAddressSchema.partial(),
        settings: UpdateContactSettingsSchema,
    })
    .refine(
        (data) => {
            if (data.countryCode === 'BG') {
                return requiredUic().safeParse(data.uic).success
            }
            return true
        },
        {
            params: {
                translationKey:
                    'validation.invalid-uic' satisfies TranslationKey,
            },
            path: ['uic'],
        },
    )

export const ContactSchema = BaseEntitySchema.extend({
    type: z.nativeEnum(ContactType).array(),
    name: requiredString(),
    uic: z.string(),
    vat: z.string(),
    email: z.string().email().or(z.literal('')),
    countryCode: requiredCountryCode(),
    defaultCurrency: SupportedCurrencySchema,
    defaultCurrencyCode: requiredString(),
    address: AddressSchema,
    settings: ContactSettingsSchema,
})

export type CreateContactRequest = z.infer<typeof CreateContactSchema>
export type UpdateContactRequest = z.infer<typeof CreateContactSchema>
export type ContactResponse = z.infer<typeof ContactSchema>
