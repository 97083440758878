import { z } from 'zod'
import { OrderDirection, type FolderResponse } from '~/schemas'
import { createOrderSchema } from '~/schemas/util'

export const FolderOrderSchema = createOrderSchema(
    z.enum(['name', 'size', 'updatedAt']),
    'name',
    OrderDirection.asc,
)

export type FolderOrderQuery = z.infer<typeof FolderOrderSchema>
export type OrderedFolderRequest = {
    order: FolderOrderQuery
}
export type OrderedFolderResponse = {
    data: FolderResponse
    order: FolderOrderQuery
}
