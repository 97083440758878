import { Permission, type Role } from '@prisma/client'

export function hasPermission(
    role: Pick<Role, 'permissions'>,
    permission: Permission,
): boolean {
    return (
        role.permissions.includes(permission) ||
        role.permissions.includes(Permission.admin)
    )
}
