import { z } from 'zod'
import { requiredUuid } from '../util'

export const InvoiceNumberingSchema = z.object({
    invoicePrefix: z
        .string()
        .min(0)
        .max(9)
        .regex(/^[0-9]{0,9}$/),
    invoiceNumber: z.number().min(0).max(999_999_999),
    proformaInvoicePrefix: z.string().min(0).max(9),
    proformaInvoiceNumber: z.number().min(0).max(999_999_999),
})
export const UpdateInvoiceNumberingSchema = InvoiceNumberingSchema

export const VatExportAccountsSchema = z.object({
    purchasesVatExportAccountId: requiredUuid().nullable(),
    salesVatExportAccountId: requiredUuid().nullable(),
})
export const UpdateVatExportAccountsSchema = VatExportAccountsSchema

export type InvoiceNumberingResponse = z.infer<typeof InvoiceNumberingSchema>
export type UpdateInvoiceNumberingRequest = z.infer<
    typeof UpdateInvoiceNumberingSchema
>
export type VatExportAccountsResponse = z.infer<typeof VatExportAccountsSchema>
export type UpdateVatExportAccountsRequest = z.infer<
    typeof UpdateVatExportAccountsSchema
>
