import { z } from 'zod'
import { FolderType } from '@prisma/client'
import { requiredString } from '../util'
import { BaseEntitySchema } from '../base/base-entity'
import { FileSchema, type FileResponse } from './file'
import { DriveUserSchema, type DriveUser } from './info'

export const CreateFolderSchema = z.object({
    name: requiredString(),
    parentId: z.string().uuid().nullable(),
})

export interface FolderResponse extends z.input<typeof BaseEntitySchema> {
    name: string
    type: FolderType
    subfolders: FolderResponse[]
    files: FileResponse[]
    parentId: string | null
    path: FolderBreadcrumb[]
    createdByUser: DriveUser | null
    createdByUserId: string | null
    updatedByUser: DriveUser | null
    updatedByUserId: string | null
}

// @ts-ignore ignore
export const FolderSchema: z.ZodType<FolderResponse> = z.lazy(() =>
    BaseEntitySchema.extend({
        name: requiredString(),
        type: z.nativeEnum(FolderType),
        subfolders: z.array(FolderSchema),
        files: z.array(FileSchema),
        parentId: z.string().uuid().nullable(),
        path: z.array(FolderBreadcrumbSchema),
        createdByUser: DriveUserSchema.nullable(),
        createdByUserId: z.string().uuid().nullable(),
        updatedByUser: DriveUserSchema.nullable(),
        updatedByUserId: z.string().uuid().nullable(),
    }),
)

export const FolderBreadcrumbSchema = z.object({
    id: z.string().uuid(),
    name: requiredString(),
    type: z.nativeEnum(FolderType),
})

export const RenameFolderSchema = z.object({
    name: requiredString(),
})

export type CreateFolderRequest = z.infer<typeof CreateFolderSchema>
export type RenameFolderRequest = z.infer<typeof RenameFolderSchema>
export type FolderBreadcrumb = z.infer<typeof FolderBreadcrumbSchema>
