import type {
    CompanyResponse,
    CreateCompanyRequest,
    UpdateCompanyRequest,
} from '~/schemas'

const baseUrl = () => '/companies'

export const companies = {
    create(createCompanyRequest: CreateCompanyRequest) {
        return apiClient<CompanyResponse>(`${baseUrl()}`, {
            method: 'POST',
            body: createCompanyRequest,
        })
    },

    findAll() {
        return apiClient<CompanyResponse[]>(`${baseUrl()}`)
    },

    findOne(companyId: string) {
        return apiClient<CompanyResponse>(`${baseUrl()}/${companyId}`)
    },

    update(companyId: string, updateCompanyRequest: UpdateCompanyRequest) {
        return apiClient<CompanyResponse>(`${baseUrl()}/${companyId}`, {
            method: 'PATCH',
            body: updateCompanyRequest,
        })
    },

    remove(companyId: string) {
        return apiClient<CompanyResponse>(`${baseUrl()}/${companyId}`, {
            method: 'DELETE',
        })
    },
}
