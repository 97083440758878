import { Permission } from '@prisma/client'

export function useRole() {
    const { company } = useSelectedCompany()

    function permitted(permission: Permission) {
        return company.value?.role.permissions.includes(permission)
    }

    const isAdmin = computed(() => permitted(Permission.admin))

    function can(...permissions: Permission[]) {
        return permissions.every(permitted)
    }

    function canSome(...permissions: Permission[]) {
        return permissions.some(permitted)
    }

    return {
        isAdmin,
        can,
        canSome,
    }
}
