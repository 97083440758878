import type {
    ExpenseResponse,
    CreateExpenseRequest,
    UpdateExpenseRequest,
    PaginatedExpensesResponse,
    ExpenseFiltersQuery,
    ExpenseOrderQuery,
    FileResponse,
    PaginationQuery,
    ExportVariant,
    FileType,
    OcrExpenseAttachmentResponse,
} from '~/schemas'
import type { ExpenseDocumentStatus } from '@prisma/client'
import type { ResourceQuantityLimit } from '~/config/subscriptions'

const baseUrl = (companyId: string) => `/companies/${companyId}/expenses`

export const expenses = {
    create(companyId: string, createExpenseRequest: CreateExpenseRequest) {
        return apiClient<ExpenseResponse>(baseUrl(companyId), {
            method: 'POST',
            body: createExpenseRequest,
        })
    },

    findAll(
        companyId: string,
        params?: {
            pagination?: PaginationQuery
            order?: ExpenseOrderQuery
            filters?: ExpenseFiltersQuery
        },
    ) {
        return apiClient<PaginatedExpensesResponse>(baseUrl(companyId), {
            params: {
                ...params?.pagination,
                ...params?.order,
                ...params?.filters,
            },
        })
    },

    findOne(companyId: string, expenseId: string) {
        return apiClient<ExpenseResponse>(`${baseUrl(companyId)}/${expenseId}`)
    },

    getTemplate(
        companyId: string,
        copyExpenseId?: string | null,
        subscriptionInvoiceId?: string,
    ) {
        return apiClient<CreateExpenseRequest>(
            `${baseUrl(companyId)}/template`,
            {
                query: {
                    copyExpenseId,
                    subscriptionInvoiceId,
                },
            },
        )
    },

    update(
        companyId: string,
        expenseId: string,
        updateExpenseRequest: UpdateExpenseRequest,
    ) {
        return apiClient<ExpenseResponse>(
            `${baseUrl(companyId)}/${expenseId}`,
            {
                method: 'PUT',
                body: updateExpenseRequest,
            },
        )
    },

    remove(companyId: string, expenseId: string) {
        return apiClient<ExpenseResponse>(
            `${baseUrl(companyId)}/${expenseId}`,
            {
                method: 'DELETE',
            },
        )
    },

    removeMany(companyId: string, ids?: string[]) {
        return apiClient<void>(`${baseUrl(companyId)}`, {
            method: 'DELETE',
            params: { ids },
        })
    },

    uploadAttachments(companyId: string, expenseId: string, files: File[]) {
        const formData = new FormData()

        Array.from(files).forEach((file) => {
            formData.append('attachment', file)
        })

        return apiClient<FileResponse[]>(
            `${baseUrl(companyId)}/${expenseId}/attachments`,
            {
                method: 'POST',
                body: formData,
            },
        )
    },

    async getSignedAttachmentUrl(
        companyId: string,
        expenseId: string,
        attachmentId: string,
    ) {
        return apiClient<FileResponse>(
            `${baseUrl(companyId)}/${expenseId}/attachments/${attachmentId}`,
        )
    },

    async downloadAttachment(
        companyId: string,
        expenseId: string,
        attachmentId: string,
    ) {
        const file = await this.getSignedAttachmentUrl(
            companyId,
            expenseId,
            attachmentId,
        )

        return downloadFileUrl(file.url, file.name)
    },

    deleteAttachment(
        companyId: string,
        expenseId: string,
        attachmentId: string,
    ) {
        return apiClient<void>(
            `${baseUrl(companyId)}/${expenseId}/attachments/${attachmentId}`,
            {
                method: 'DELETE',
            },
        )
    },

    updateStatus(
        companyId: string,
        expenseId: string,
        status: ExpenseDocumentStatus,
    ) {
        return apiClient<void>(`${baseUrl(companyId)}/${expenseId}/status`, {
            method: 'PUT',
            body: { status },
        })
    },

    async downloadAll(
        companyId: string,
        params: {
            fileType: FileType
            variant: ExportVariant
            order?: ExpenseOrderQuery
            filters?: ExpenseFiltersQuery
        },
    ): Promise<void> {
        const { _data: blob, headers } = await apiClient.raw<Blob, 'blob'>(
            `${baseUrl(companyId)}/download`,
            {
                params: {
                    fileType: params.fileType,
                    variant: params.variant,
                    ...params?.order,
                    ...params?.filters,
                },
                responseType: 'blob',
            },
        )

        const fileName = getFileNameFromHeaders(headers)

        if (!fileName) {
            $sentry.captureMessage('File name not found in headers')
        }

        downloadFileBlob(blob!, fileName || `export.${params.fileType}`)
    },

    aiScan(companyId: string, isVatRegistered: boolean, file: File) {
        const formData = new FormData()
        formData.append('file', file, file.name)

        return apiClient<OcrExpenseAttachmentResponse>(
            `${baseUrl(companyId)}/ai-scan`,
            {
                method: 'POST',
                body: formData,
                query: { isVatRegistered },
            },
        )
    },

    aiScansLeftThisMonth(companyId: string) {
        return apiClient<ResourceQuantityLimit>(
            `${baseUrl(companyId)}/ai-scan-count-limit`,
        )
    },

    countLeftThisMonth(companyId: string) {
        return apiClient<ResourceQuantityLimit>(
            `${baseUrl(companyId)}/count-limit`,
        )
    },
}
