import type { FileResponse, NextDocumentNumberResponse } from '~/schemas'
import type { RevenueDocumentType } from '@prisma/client'
import type {
    InvoiceNumberingResponse,
    UpdateInvoiceNumberingRequest,
    UpdateVatExportAccountsRequest,
    VatExportAccountsResponse,
} from '~/schemas/company/company-settings'

const baseUrl = (companyId: string) => `/companies/${companyId}/settings`

export const settings = {
    uploadLogo(companyId: string, logo: File) {
        const formData = new FormData()
        formData.append('logo', logo)

        return apiClient<FileResponse>(`${baseUrl(companyId)}/logo`, {
            method: 'PUT',
            body: formData,
        })
    },

    removeLogo(companyId: string) {
        return apiClient<void>(`${baseUrl(companyId)}/logo`, {
            method: 'DELETE',
        })
    },

    nextDocumentNumber(
        companyId: string,
        documentType: RevenueDocumentType,
        prefix: string,
    ) {
        return apiClient<NextDocumentNumberResponse>(
            `${baseUrl(companyId)}/next-document-number`,
            {
                query: {
                    documentType,
                    prefix,
                },
            },
        )
    },

    getInvoiceNumbering(companyId: string) {
        return apiClient<InvoiceNumberingResponse>(
            `${baseUrl(companyId)}/invoice-numbering`,
        )
    },

    updateInvoiceNumbering(
        companyId: string,
        updateInvoiceNumberingRequest: UpdateInvoiceNumberingRequest,
    ) {
        return apiClient<InvoiceNumberingResponse>(
            `${baseUrl(companyId)}/invoice-numbering`,
            {
                method: 'PUT',
                body: updateInvoiceNumberingRequest,
            },
        )
    },

    getVatExportAccounts(companyId: string) {
        return apiClient<VatExportAccountsResponse>(
            `${baseUrl(companyId)}/vat-export-accounts`,
        )
    },

    updateVatExportAccounts(
        companyId: string,
        updateVatExportAccountsRequest: UpdateVatExportAccountsRequest,
    ) {
        return apiClient<VatExportAccountsResponse>(
            `${baseUrl(companyId)}/vat-export-accounts`,
            {
                method: 'PUT',
                body: updateVatExportAccountsRequest,
            },
        )
    },
}
