import { hasPermission } from '~/utils/hasPermission'

export default defineNuxtRouteMiddleware((to, _from) => {
    const { selectedCompany } = useAuthStore()

    if (selectedCompany && to.meta.permissions) {
        for (const permission of to.meta.permissions) {
            if (!hasPermission(selectedCompany.role, permission)) {
                return abortNavigation()
            }
        }
    }
})
