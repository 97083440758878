import { z } from 'zod'
import type { TranslationKey } from '~/types/i18n'

function isUICorEGN(str: string): boolean {
    return /^[0-9]{9,10}$/.test(str)
}

export const requiredUic = () =>
    z.string().refine(isUICorEGN, {
        params: {
            translationKey: 'validation.invalid-uic' satisfies TranslationKey,
        },
    })
