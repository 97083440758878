import { z } from 'zod'
import { BaseEntitySchema } from '~/schemas/base/base-entity'
import { requiredString } from '~/schemas/util'
import { DriveUserSchema } from './info'
import { FolderBreadcrumbSchema } from './folder'

export const FileSchema = BaseEntitySchema.extend({
    name: requiredString(),
    mimetype: requiredString(),
    size: z.number(),
    url: requiredString(),
    parentId: z.string().uuid().nullable(),
    createdByUser: DriveUserSchema.nullable(),
    createdByUserId: z.string().uuid().nullable(),
    updatedByUser: DriveUserSchema.nullable(),
    updatedByUserId: z.string().uuid().nullable(),
    path: z.array(FolderBreadcrumbSchema).optional(),
})

export const RenameFileSchema = z.object({
    name: requiredString(),
})

export type FileResponse = z.infer<typeof FileSchema>
export type RenameFileRequest = z.infer<typeof RenameFileSchema>
