import { z } from 'zod'

export const BulkMoveSchema = z.object({
    folderOrFileIds: z.array(z.string().uuid()).min(1),
    newFolderId: z.string().uuid(),
})

export const BulkDeleteSchema = z.object({
    folderOrFileIds: z.array(z.string().uuid()).min(1),
})

export type BulkMoveRequest = z.infer<typeof BulkMoveSchema>
export type BulkDeleteRequest = z.infer<typeof BulkDeleteSchema>
