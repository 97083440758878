export function downloadFileBlob(blob: Blob, fileName: string) {
    const link = document.createElement('a')

    link.href = URL.createObjectURL(blob)
    link.download = fileName
    link.click()

    URL.revokeObjectURL(link.href)
    link.remove()
}

export async function downloadFileUrl(url: string, fileName: string) {
    const blob = await getBlobFromUrl(url)

    downloadFileBlob(blob, fileName)
}

export async function getBlobFromUrl(
    url: string,
    mimetype?: string,
): Promise<Blob> {
    const response = await $fetch<Blob>(url, { responseType: 'blob' })

    return new Blob([response], { type: mimetype })
}

/**
 * Extracts the filename from the Content-Disposition header of a response.
 */
export const getFileNameFromHeaders = (headers: Headers): string | null => {
    const header = headers.get('Content-Disposition')
    const match = header?.match(/filename="([^"]+)"/)

    return match ? decodeURIComponent(match[1]) : null
}
