import { z } from 'zod'
import { requiredString } from '../util'
import { FolderSchema } from './folder'

export const DriveInfoResponseSchema = z.object({
    // folderTree: ???,
    totalSize: z.number(),
    rootFolder: FolderSchema,
})

export const SearchDriveQuerySchema = z.object({
    search: requiredString(),
})

// TEMP: This is a temporary schema to be used for "created by" and "updated by"
// Ideally we want to use CompanyUserSchema from user.ts, but some import order issues are preventing that
export const DriveUserSchema = z.object({
    id: z.string().uuid(),
    firstName: requiredString(),
    lastName: requiredString(),
})
export type DriveUser = z.infer<typeof DriveUserSchema>

export type DriveInfoResponse = z.infer<typeof DriveInfoResponseSchema>
export type SearchDriveQuery = z.infer<typeof SearchDriveQuerySchema>
