import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
    const { vueApp } = nuxtApp
    const runtimeConfig = useRuntimeConfig()
    const shouldSendEvents =
        runtimeConfig.public.APP_ENV === 'staging' ||
        runtimeConfig.public.APP_ENV === 'production'

    Sentry.init({
        enabled: shouldSendEvents,
        app: vueApp,
        dsn: 'https://05d445959edc44cd8ba6c438e2af28a8@o4504759511744512.ingest.sentry.io/4504759520002048',
        environment: runtimeConfig.public.APP_ENV,
        beforeSend(event, hint) {
            console.log(`[Sentry] (${hint.originalException})`, {
                event,
                hint,
            })

            return shouldSendEvents ? event : null
        },
        integrations: [
            Sentry.browserTracingIntegration({
                router: useRouter(),
            }),
            Sentry.replayIntegration({
                networkDetailAllowUrls: [window.location.origin],
            }),
        ],
        // Capture 100% of transactions for performance monitoring
        tracesSampleRate: 1.0,
        // Capture Replay for 100% of sessions with an error
        replaysOnErrorSampleRate: 1.0,
    })

    vueApp.mixin(
        Sentry.createTracingMixins({
            trackComponents: true,
            timeout: 2000,
            hooks: ['activate', 'mount', 'update'],
        }),
    )
    Sentry.attachErrorHandler(vueApp, {
        logErrors: true,
        attachProps: true,
        attachErrorHandler: true,
        trackComponents: true,
        timeout: 2000,
        hooks: ['activate', 'mount', 'update'],
    })
})
