import { z } from 'zod'
import { OrderDirection, type PaginationQuery } from '~/schemas/common'
import {
    createFiltersSchema,
    createOrderSchema,
    createPaginatedResponseSchema,
    requiredDateTime,
    requiredUuid,
} from '~/schemas/util'
import { DebitNoteSchema } from './debit-note'
import { DebitNoteStatus } from './debit-note-status'

export const DebitNoteOrderSchema = createOrderSchema(
    z.enum([
        'displayNumber',
        'contact.name',
        'issueDate',
        'totalInBaseCurrency',
        'createdAt',
    ]),
    'createdAt',
    OrderDirection.desc,
)

export const DebitNoteFiltersSchema = createFiltersSchema(
    z.object({
        contactId: requiredUuid().optional(),
        search: z.string().optional(),
        dateFrom: requiredDateTime().optional(),
        dateTo: requiredDateTime().optional(),
        status: z
            .union([
                z.nativeEnum(DebitNoteStatus),
                z.array(z.nativeEnum(DebitNoteStatus)),
            ])
            .optional()
            .transform((value) =>
                value && typeof value === 'string' ? [value] : value,
            ),
    }),
)
export const PaginatedDebitNotesSchema = createPaginatedResponseSchema(
    DebitNoteSchema,
    DebitNoteOrderSchema,
    DebitNoteFiltersSchema,
)

export type DebitNoteOrderQuery = z.infer<typeof DebitNoteOrderSchema>
export type DebitNoteFiltersQuery = z.infer<typeof DebitNoteFiltersSchema>
export type PaginatedDebitNotesRequest = {
    pagination: PaginationQuery
    order: DebitNoteOrderQuery
    filters: DebitNoteFiltersQuery
}
export type PaginatedDebitNotesResponse = z.infer<
    typeof PaginatedDebitNotesSchema
>
