import { PRIVACY_POLICY_COOKIE } from '~/config/constants'

export const useAppStore = defineStore('app', () => {
    const isProd = process.env.NODE_ENV === 'production'
    const route = useRoute()
    const authStore = useAuthStore()
    const sidebarStorageState = useLocalStorage('sidebar', true)
    const privacyConcentAgreed = useCookie<boolean>(
        PRIVACY_POLICY_COOKIE,
        // 1 year
        {
            expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
            domain: isProd ? '.efirma.bg' : 'localhost', // Share across all subdomains
            path: '/', // Accessible site-wide
            secure: isProd, // Only secure in production (HTTPS)
            sameSite: 'lax', // Protect from CSRF attacks
        },
    )

    const desktopSidebarOpen = ref(false)
    const mobileSidebarOpen = ref(false)

    const hideSidebar = ref(false)
    const isSidebarAllowed = computed(() => {
        return (
            route.meta.layout !== 'auth' &&
            !!authStore.selectedCompany &&
            !hideSidebar.value
        )
    })
    const isPrivacyConcentAgreed = computed(() => privacyConcentAgreed.value)

    function toggleSidebar() {
        sidebarStorageState.value = !sidebarStorageState.value

        breakpoint.lg.value
            ? (desktopSidebarOpen.value = !desktopSidebarOpen.value)
            : (mobileSidebarOpen.value = !mobileSidebarOpen.value)
    }

    function allowPrivacyCookies(allow: boolean) {
        privacyConcentAgreed.value = allow
    }

    return {
        desktopSidebarOpen,
        mobileSidebarOpen,
        sidebarStorageState,
        hideSidebar,
        isSidebarAllowed,
        isPrivacyConcentAgreed,
        toggleSidebar,
        allowPrivacyCookies,
    }
})
